.home-wrapper {
  display: flex;
  justify-content: center;
  background-color: #000;
  color: #fff;
  padding: 80px 0;
  align-items: center;
  flex-direction: column;
}

.home-wrapper-info {
  display: flex;
  justify-content: space-between;
}

.col-slide {
  max-width: 49%;
  width: 100%;
}

.col-left-slide > h4 {
  font-size: 18px;
  font-weight: 400;
}

.col-left-slide > h1 {
  font-size: 50px;
  color: #ffffff;
  margin-bottom: 0;
  font-size: 60px;
  font-weight: 700;
}

.col-left-slide > h3 {
  color: #04c37f;
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.col-left-slide > p {
  color: #ccc;
  margin-bottom: 0;
  line-height: 1.8;
  font-size: 15px;
}

.col-cards {
  width: 100%;
  display: flex;
  justify-content: center;
}

.col-cards-info > ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0;
}

.col-cards-info > ul > li {
  background-color: #0c0c0c;
  position: relative;
  z-index: 1;
  padding: 35px 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
}