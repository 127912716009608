.top-bar {
  background-color: #04c37f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.top-info-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .9rem;
  padding: 15px 0;
  font-weight: 400;
}

.top-info-bar > ul {
  display: flex;
  gap: 20px;
  list-style-type: none;
}

.top-info-bar > ul > li {
  display: flex;
  gap: 10px;
}

.navigation-bar {
  background-color: #000;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.navigation-info-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.logo-header { 
  text-decoration: none;
}

.logo-header > h2 {
  border-radius: 20px;
  display: flex;
  padding: 6px 20px;
  align-items: center;
  color: #04c37f;
  justify-content: center;
}

.navigation-info-bar > nav {
  display: flex;
  align-items: center;
}

.navigation-info-bar > nav > ul {
  display: flex;
  gap: 20px;
  list-style-type: none;
}

.navigation-info-bar > nav > ul > li > a {
  cursor: pointer;
  font-weight: 500;
  color: #ffffff;
  font-size: 15px;
  transition: .3s;
  text-decoration: none;  
}

.navigation-info-bar > nav > ul > li > a > span {
  color: #04c37f;
  padding-right: 2px;
}

.button-prim-ghost {
  border: 1px solid #04c37f;
  margin-left: 20px;
  padding: 10px 20px;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
}

.link {
  position: relative;
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  margin-top: 1px;
  background: #04c37f;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: '';
} 

.link--metis::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.link--metis:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

footer {
  border: 1px solid;
  display: flex;
  justify-content: center;
  background-color: #111111;
}

.footer-info {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 100px;
  color: #ffff;
  padding-bottom: 100px;
}

.col-fot {
  display: flex;
  flex-direction: column;
  max-width: 33.333%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.developer-info > h2 {
  font-weight: 500 !important;
  font-size: 2rem !important;
  border: none !important;
  margin-bottom: 10px !important;
  padding: none !important;
}

.col-fot > h2 {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid #1d1d1d;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.developer-info > p {
  font-size: 14px;
  line-height: 1.8;
  line-height: 1.7;
  color: #cccccc;
  font-size: 14px;
}

.developer-info > ul, .col-left-slide > ul{
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 0;
  gap: 5px;
  display: flex;
}

.developer-info > ul > li, .col-left-slide > ul > li{
  font-size: 13px;
  margin-left: 2px;
  margin-right: 2px; 
}

.developer-info > ul > li > a, .col-left-slide > ul > li > a{
  color: #e2e2e2;
  background: #1b1b1b;
  width: 35px;
  height: 35px;
  text-align: center;
  display: inline-block;
  border-radius: 100px;
  line-height: 35px;
  cursor: pointer;
  transition: .3s;
}

.developer-info > ul > li > a:hover, .col-left-slide > ul > li > a:hover{
  background-color: #04c37f;
  transform: translateY(-2px);
}

.col-fot > ul {
  list-style-type: none;
}


.footer-list > ul > li {
  padding-left: 20px;
  position: relative;
  margin-bottom: 8px;
  line-height: 1.8;
  font-size: 14px;
  color: #cccccc;
}

.footer-list > ul > li::before{
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 0px 10px 10px 0px;
}

.footer-list > ul > li::before{
  background-color: #04c37f;
}

.footer-list > ul > li > a{
  color: #cccccc; 
  transition: .3s;
  text-decoration: none;
}

.footer-list > ul > li > a:hover{
  color: #04c37f;
}

.contact-list > ul{
  display: flex;
  flex-direction: column;
}

.contact-list > ul > li {
  display: flex;
  position: relative;
  margin-bottom: 15px !important;
  color: #cccccc;
  position: relative;
  cursor: text;
  padding-left: 50px;
}

.contact-list > ul > li > i {
  position: absolute;
  left: 0;
  top: 0;
  background: #1b1b1b;
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #04c37f;
}

.contact-list > ul > li > div > span {
  font-weight: 500;
  color: #fff;
}

.copyright-col {
  background-color: #000;
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #cccccc;
}

.error-page {
  display: flex;
  justify-content: center ;
  background-color: #000;
}

.error-page-info {
  display: flex;
  align-items: center;
  padding: 50px 0;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.error-page-info > h2 {
  font-size: 10rem;
  margin: 0;
  padding: 0;
}

.error-page-info > h3 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2rem;
  margin-top: -40px;
}

.button-prim {
  background-color: #04c37f;
  padding: 10px 20px;
  margin-top: 30px;
  cursor: pointer;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  transition: .3s;
}

.button-prim:hover {
  Background-color: #fff;
  color: #000;
}

.navigation-link-to-website {
  color: #04c37f;
}